import React from 'react';
import { css } from '@emotion/core';
import { Desktop, Tablet } from '../../constants/responsive';
import { FormattedHTMLMessage, Link, useIntl } from 'gatsby-plugin-intl';
import Container from '../../components/Container';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import BannerQuote from '../../components/BannerQuote';
import CareerJobList from '../../components/CareerJobList';
import ScrollContainer from 'react-indiana-drag-scroll';
import Blank from '../../components/Blank';
import { useMount } from 'react-use';

const InfoSubmitted = () => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "career/banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      one: file(relativePath: { eq: "career/1.png" }) {
        childImageSharp {
          fluid(maxWidth: 270) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      two: file(relativePath: { eq: "career/2.png" }) {
        childImageSharp {
          fluid(maxWidth: 270) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      three: file(relativePath: { eq: "career/3.png" }) {
        childImageSharp {
          fluid(maxWidth: 270) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      four: file(relativePath: { eq: "career/4.png" }) {
        childImageSharp {
          fluid(maxWidth: 270) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  const StepRow = () => {
    return (
      <div
        css={css`
          width: 100%;
          display: flex;
          border-bottom: 1px solid rgba(220, 220, 220, 0.8);
          :last-child {
            border-bottom: none;
          }
          flex-direction: column;
          padding: 19.5px 0;
          ${Tablet} {
            flex-direction: row;
            padding: 18px 0;
          }
          ${Desktop} {
            padding: 30px 0;
          }
        `}
      >
        <h3
          css={css`
            margin: 0;
            white-space: nowrap;
            font-size: 16px;
            font-weight: 500;
            line-height: 1.5;
            ${Tablet} {
              font-size: 18px;
              font-weight: 500;
              line-height: 1.5;
            }
            ${Desktop} {
              font-size: 22px;
              font-weight: bold;
              line-height: 1.5;
            }
          `}
        >
          Step 1
        </h3>
        <Blank width={[0, 24, 50]} height={[5, 0, 0]} />
        <p
          css={css`
            font-size: 16px;
            font-weight: normal;
            line-height: 1.5;
            ${Tablet} {
              font-size: 14px;
              font-weight: normal;
              line-height: 1.29;
            }
            ${Desktop} {
              font-size: 20px;
              font-weight: normal;
              line-height: 1.2;
            }
          `}
        >
          We will review your application and reach out to you within two weeks.
          If we think you will be a good fit for us, we will set up a call to
          get to know you better!
        </p>
      </div>
    );
  };

  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: 'career.title' })}
        description={intl.formatMessage({ id: 'career.description' })}
        keywords={intl.formatMessage({ id: 'career.keywords' })}
      />
      <div
        css={css`
          background: #f2f2f2;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
        `}
      >
        <Container
          css={css`
            display: flex;
            flex-direction: column;

            ${Tablet} {
            }
            ${Desktop} {
            }
          `}
        >
          <Blank height={[50, 100, 150]} />
          <h2
            css={css`
              text-align: center;
              font-size: 24px;
              font-weight: 500;
              line-height: 1.33;
              margin-bottom: 20px;
              ${Tablet} {
                font-size: 36px;
                font-weight: 500;
                line-height: 1.33;
              }
              ${Desktop} {
                font-size: 36px;
                font-weight: bold;
                line-height: 0.92;
              }
            `}
          >
            Congratulations, your application has been submitted!
          </h2>
          <h4
            css={css`
              margin: 0;
              text-align: center;
              font-size: 20px;
              font-weight: 500;
              line-height: normal;
              ${Tablet} {
                font-size: 24px;
                font-weight: 500;
                line-height: 2;
              }
              ${Desktop} {
                font-size: 24px;
                font-weight: 500;
                line-height: 1.38;
              }
            `}
          >
            What’s Next?
          </h4>
          <Blank height={[40, 150, 98]} />
          <div
            css={css`
              border-radius: 15px;
              box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.08);
              background-color: #ffffff;
              max-width: 100%;
              padding: 0 20px;
              ${Tablet} {
                max-width: 704px;
                padding: 0 47px;
              }
              ${Desktop} {
                max-width: 1206px;
                padding: 0 102px;
              }
            `}
          >
            <StepRow />
            <StepRow />
            <StepRow />
          </div>
          <Blank height={[50, 40, 54]} />
          <Link>
            <div
              css={css`
                color: #ee2f79;
                text-align: center;
                text-decoration: underline;
                font-size: 14px;
                font-weight: normal;
                line-height: normal;
                ${Tablet} {
                  font-size: 14px;
                  font-weight: normal;
                  line-height: normal;
                }
                ${Desktop} {
                  font-size: 16px;
                  font-weight: normal;
                  line-height: normal;
                }
              `}
            >
              Back to Career Page
            </div>
          </Link>
          <Blank height={[70, 277, 522]} />
        </Container>
      </div>
    </Layout>
  );
};

export default InfoSubmitted;
